<template>
    <div>
        <div id='printMe' style=' width: 700px; margin: 0 auto;'>
            <div v-if='showForm' class='yijianBox A4Container flexCloumnCenter'>
                <div class='title'>{{ month }}月份实习单位考评意见表</div>
                <div class='form'>
                    <el-row class='el-row borderB borderT'>
                        <el-col :span='4' class='flexCenter cell'>
                            <div class='first' style='width: 100%;height: 100%;'>
                                <div class='first-left'>项目</div>
                                <div class='first-right'>等次</div>
                            </div>
                        </el-col>
                        <el-col :span='20' class=''>
                            <el-row class='' style='width: 100%;'>
                                <el-col :span='6' class='flexCenter cell'>优秀</el-col>
                                <el-col :span='6' class='flexCenter cell'>良好</el-col>
                                <el-col :span='6' class='flexCenter cell'>合格</el-col>
                                <el-col :span='6' class='flexCenter cell' style='border-right:0;'>不合格</el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row class='el-row borderB' v-for='(item, index) in list'>
                        <el-col :span='4' class='flexCenter cell'>{{ item.name }}</el-col>
                        <el-col :span='20' class=''>
                            <el-row class='' style='width: 100%;'>
                                <el-col :span='6' class='flexCenter cell'></el-col>
                                <el-col :span='6' class='flexCenter cell'></el-col>
                                <el-col :span='6' class='flexCenter cell'></el-col>
                                <el-col :span='6' class='flexCenter cell' style='border-right:0;'></el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-row class='el-row'
                        style='border-bottom:0;width:100%;height: calc( 100% - 561px );padding: 10px;position: relative;box-sizing: border-box;'>
                        评语：
                        <div class='flexBetween'
                            style='position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 50px;'>
                            <div>考核人（签字）:</div>
                            <div>（单位盖章）</div>
                            <div style="text-indent:0;">
                                年<span style="display:inline-block;width: 20px;height: 10px"></span>
                                月<span style="display:inline-block;width: 20px;height: 10px"></span>
                                日
                            </div>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'unitEvaluation',
    data() {
        return {
            dataover: false,
            userid: '',
            list: [
                { name: '文明礼仪' },
                { name: '诚实守信' },
                { name: '遵守制度' },
                { name: '出勤情况' },
                { name: '工作态度' },
                { name: '技能水平' },
                { name: '完成任务' },
                { name: '工作质量' },
                { name: '团队协作' },
                { name: '综合评价' }
            ],
            month: '',
            showForm: false
        }
    },
    mounted() {
        let _this = this
        let month = this.getParams('month')
        setTimeout(function () {
            let myDate = new Date()
            let curMonth = myDate.getMonth()       //获取当前月份(0-11,0代表1月)
            _this.month = month ? month : `${curMonth + 1}`
            _this.showForm = true
        }, 200)
    },
    methods: {
        getParams(name) {
            let n_url = window.location.href
            n_url = n_url.replace('/#', '')
            if (n_url.indexOf('?') > 0) {
                n_url = n_url.split('?')[1]
            }
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
            var r = n_url.match(reg)
            if (r != null) return unescape(r[2])
            return null
        },
    }
}
</script>

<style lang='less' type='text/less' scoped>
@import "/public/css/pdf_default.less";

.yijianBox .ant-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.yijianBox .ant-row {
    padding: 0;
    margin: 0;
}

.yijianBox {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .first {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .first::before {
        content: "";
        position: absolute;
        width: calc(100% / 0.906); //cos(25)=0.906
        height: 1px;
        /*这里需要自己调整，根据td的宽度和高度*/
        top: 25px;
        left: -5px;
        background-color: black;
        display: block;
        transform: rotate(25deg);
        /*这里需要自己调整，根据线的位置*/
        transform-origin: top;
    }

    .first-left {
        position: absolute;
        left: 10px;
        bottom: 5px;
    }

    .first-right {
        position: absolute;
        top: 5px;
        right: 10px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: 1px solid #000;
        border-top: 0;
        overflow: hidden;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .el-row {
            // border-top: 1px solid #000;
            //border-left: 1px solid #000;

            .cell {
                height: 50px;
                // border-top: 1px solid #000;
                border-right: 1px solid #000;
                word-break: break-all;
            }

        }

        .borderB {
            border-bottom: 1px solid #000;
        }

        .borderT {
            border-top: 1px solid #000;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
</style>
